.MessageBox-container {
  display: flex;
  flex: 1;
  width: 100%;

  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
}

.MessageBox-input {


  width: 100%;
  max-width: 900px;
  height: 25px;
  
  color: #fff;
  background-color: #272822;
  border: 1px solid #ffff80;
  font-family: 'Source Code Pro', monospace;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
}


@media screen and (max-width: 1000px) {
  .MessageBox-input{
    width: 90%;
  }
}


@media screen and (max-height: 800px) {
  .MessageBox-input {
    height: 60%;
  }
}

