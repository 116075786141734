
html, body, #root {  
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    margin: 0;

    background-color: #1a1a1a;
    font-family: 'Source Code Pro', monospace;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    
}

@media screen and (max-width: 1000px) {
  html, body, #root {  
    height: 145vh;
    overflow-y: unset; 
  }
}

@media screen and (max-height: 600px) {
  html, body, #root {  
    height: 170vh;
  }
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: #fff;
}

::selection {
  color: black;
  background: #fff;
}
