.RadioButton-button {
      width: 100px;
      height: 25px;
      border: 0px;
      background-color: #272822;
      font-family: 'Source Code Pro', monospace;
      color: #fff;
    }
  
.RadioButton-object-container {
    border: 1px solid #ffff80;
    border-radius: 4px;
}


.RadioButton-container {
      display: flex;
      flex: 1;
      border-radius: 4px;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }


    @media screen and (max-height: 800px) {
      .RadioButton-button {
        height: 60%;
      }
    }
  
