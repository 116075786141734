.KeyPair-key {
  border: 1px solid #ffff80;
  border-radius: 4px;
  overflow: hidden;
}

.KeyPair-key-highlighted {
  border: 1px solid #ffff80;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 16px 10px rgba(255, 255, 128,0.2);
}

.KeyPair-small-info {
  margin: 0px;
  font-size: 12px;
  padding: 5px;
  color: #fff;
}
 
.KeyPair-container {
  display: flex;
  flex: 4;
  flex-direction: row;
  text-align: center;
  
  
  justify-content: center;
  align-items: center ;
  
  width: 100%;
}

.KeyPair-object-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  text-align: center;
  
  justify-content: space-between;
  align-items: center ;
  
  width: 100%;
  max-width: 900px;
  background-color: #1a1a1a;
}



@media screen and (max-width: 1000px) {
  .KeyPair-object-container {
    flex-direction: column;
    justify-content: space-around ;
    height: 100%;
    
  }
  .KeyPair-key {
    margin: 5px;
  }
  .KeyPair-container {
    display: flex;
    flex: 7;
    align-items: space-around ;
  }
}

@media screen and (max-height: 800px) {
  .KeyPair-container {
    flex: 9;
  }
}

