.App {
  display: flex;
  flex: 1;

  align-items: center;
  justify-content: center;  
  flex-direction: column;
}

.App-header {
  display: flex;
  flex: 6;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  

  width: 100%;
  font-size: calc(25px + 1vmin);
  font-weight: 200;
  color: #ffff80;
}
.App-body {
  display: flex;
  flex: 25;
  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.App-logo {
  width: 75px;
  height: 75px;
}


.App-footer{
  display: flex;
  width: 100%;
  justify-content: center;
  flex: 1;
}


.poopdick{
  height: 100%;
}

p {
  color: #fff;
  font-size: calc(6px + 1vmin);
  padding: 0px;
  text-align: center;
  margin: 0px;
}

a {
  color: #fff;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  margin: 0px;
}
 

a:hover {
  color: #ffff80;
}

