.FileUpload-container {

    display: flex;
    flex: 3;
    
    width: 100%;

    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .FileUpload-object-container {
    color: #fff;
    border: 1px dashed #ffff80;
    background-color: #272822;
    
    display: flex;
    /* flex: 1; */
    height: 75%;

    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    width: 100%;
    max-width: 900px;
  }

  
.FileUpload-preview {
  height: 50px;
}




@media screen and (max-width: 1000px) {
  .FileUpload-object-container  {
    height: 100px;
    width: 90%;
  }
}


.FileUpload-link {
  font-family: 'Source Code Pro', monospace;
  
}


.FileUpload-link:hover {
    color: #ffff80;
  }
  