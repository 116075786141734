.ActionButtons-button:active {
  /* transform: translateY(2px); */
  background-color: #ffff80;
  color: black;
}


.ActionButtons-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.ActionButtons-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.ActionButtons-button:hover span {
  padding-right: 25px;
}

.ActionButtons-button:hover span:after {
  opacity: 1;
  right: 0;
}

.ActionButtons-button {
    margin-top: 10px;
    width: 100px;
    height: 25px;
    margin: 5px;
    border-radius: 4px;
    border: 1px solid #ffff80;
    background-color: #272822;
    font-family: 'Source Code Pro', monospace;
    color: #fff;
  }
  
.ActionButtons-container {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }


  @media screen and (max-height: 800px) {
    .ActionButtons-button {
      height: 60%;
    }
  }

  
